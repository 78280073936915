import React, { Fragment } from 'react';
import Hero from '../components/hero';
import SEO from '../components/seo';

export default function About() {
  return (
    <Fragment>
      <SEO title="About" />
      <Hero title="About" colors={['#0f0', '#00f']} />
      <div className="container">
		    <p className="my-4">Richard Lehmann is a creative coder with over 20 years experience in design and development. In 2005 he worked at Sony Music Entertainment as Senior Digital Art Director where he designed and developed one of the first interactive music video experiences for the band Broken Bells. In 2010 worked at Code and Theory where he won a FWA award for Maybelline’s digital campaign "The Girl With The Big Eyes," built digital installations for Brown Forman, and created webgl visualizations for Foursquare. In 2015 he was a Design Technologist at co:collective where he designed and built prototypes for Microsoft and Macy's. From 2016 to present he has been a freelancer working on a range of projects for Adidas, Adult Swim, CNN, Dolby, the Intercept, and ServiceNow.</p>
      </div>
    </Fragment>
  )
}

